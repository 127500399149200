<template>
  <v-container>
    <!--Transaction Summary-->
    <TransactionSummary :value="value" />
    <!--Header-->
    <v-row class="mb-5">
      <v-col>
        <h3>Select Transaction</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          v-model="selected_transaction"
          :search="search"
          show-select
          single-select
          item-key="transaction_id"
          :headers="headers"
          :items="
            bank_transactions.filter(
              (item) => item.transaction_type != value.transaction_type
            )
          "
          sort-by="transaction_number"
          class="elevation-0"
          :hide-default-footer="true"
          dense
          :item-class="suggestedMatch"
        >
          <!--Search bar && filters-->
          <template v-slot:top>
            <v-spacer />
            <v-text-field
              v-model="search"
              dense
              outlined
              placeholder="Search"
              append-icon="mdi-magnify"
            ></v-text-field>
          </template>
          <!--Format Amount-->
          <template v-slot:item.transaction_amount="{ item }">
            {{ formatAsCurrency("R", item.transaction_amount) }}
          </template>
          <!--Click o view transaction in new tab-->
          <template v-slot:item.transaction_number="{ item }">
            <a @click="viewInNewTab(item)">{{ item.transaction_number }}</a>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!--Divider-->
    <v-row class="my-3">
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <!--Buttons-->
    <v-row>
      <v-col>
        <AllocationBackBtn @passBack="$emit('passBack', $event)" />
        <!--Reconcile-->
        <v-btn
          color="primary"
          @click="createInterAccountTransfer()"
          :disabled="this.selected_transaction.length < 1"
          :loading="loading"
        >
          Create Inter Account Transfer
          <v-icon right>mdi-check</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import db from "../../../../../components/firebaseInit";
import firebase from "firebase";
import { formatAsCurrency } from "../../../../../composables/external";
import { showSnackbar } from "../../../../../globalActions/index";
export default {
  name: "InterAccountTransfersForm",
  props: ["value"],
  components: {
    AllocationBackBtn: () => import("../AllocationBackBtn.vue"),
    TransactionSummary: () => import("../TransactionSummary.vue"),
  },
  data() {
    return {
      loading: false,
      search: "",
      bank_transactions: [],
      selected_transaction: [],
      headers: [
        { text: "Bank Account", value: "bank_account_name", align: "start" },
        { text: "Date", value: "transaction_date" },
        { text: "Transaction #", value: "transaction_number" },
        { text: "Type", value: "transaction_type" },
        { text: "Amount", value: "transaction_amount" },
      ],
    };
  },
  created() {
    this.getBankTransactions();
  },
  methods: {
    formatAsCurrency,
    //  Highlight suggested matches in green on the data-table
    suggestedMatch(item) {
      const itemDate = new Date(this.value.transaction_date);
      const datePlus1 = () => {
        const itemDatePlus1 = itemDate.setDate(itemDate.getDate() + 1);
        return new Date(itemDatePlus1).toISOString().slice(0, 10);
      };
      const dateMinus1 = () => {
        const itemDatePlus1 = itemDate.setDate(itemDate.getDate() - 1);
        return new Date(itemDatePlus1).toISOString().slice(0, 10);
      };
      console.log(datePlus1());
      const testQuery =
        item.transaction_date === this.value.transaction_date ||
        item.transaction_date === datePlus1() ||
        item.transaction_date === dateMinus1();
      return testQuery ? "green lighten-4" : "";
    },
    // View bank transaction in new tab
    viewInNewTab(item) {
      const routeData = this.$router.resolve({
        path: "/bank-statements",
        query: {
          search: item.transaction_number,
          start_date: item.transaction_date,
        },
      });
      window.open(routeData.href, "_blank");
    },
    getBankTransactions() {
      //    Convert all transaction amount to positive numbers
      db.collection("bank_transactions")
        .where("transaction_allocation_status", "==", "No")
        .where(
          "transaction_amount",
          "==",
          Math.abs(this.value.transaction_amount)
        )
        .where("bank_account_id", "!=", this.value.bank_account_id)
        .onSnapshot((doc) => {
          this.bank_transactions = [];
          doc.forEach((doc) => {
            const data = doc.data();
            this.bank_transactions.push(data);
          });
        });
    },
    createInterAccountTransfer() {
      this.loading = true;
      this.selected_transaction = this.selected_transaction[0];
      const batch = db.batch();
      const newDebitRef = db
        .collection("bank_transactions")
        .doc(this.selected_transaction.transaction_id);

      // 1. Associating the two transactions' details to one another
      batch.update(newDebitRef, {
        transaction_allocation_status: "Inter Account Transfer",
        related_transaction_type: this.value.transaction_type,
        related_transaction_id: this.value.transaction_id,
        related_transaction_date: this.value.transaction_date,
        related_transaction_number: this.value.transaction_number,
        related_transaction_bank: this.value.bank_account_name,
      });
      const newCreditRef = db
        .collection("bank_transactions")
        .doc(this.value.transaction_id);
      batch.update(newCreditRef, {
        transaction_allocation_status: "Inter Account Transfer",
        related_transaction_type: this.selected_transaction.transaction_type,
        related_transaction_id: this.selected_transaction.transaction_id,
        related_transaction_date: this.selected_transaction.transaction_date,
        related_transaction_number:
          this.selected_transaction.transaction_number,
        related_transaction_bank: this.selected_transaction.bank_account_name,
      });
      //  ACCOUNTING LOGIC
      // 2. Reduce Suspense Account totals in correct months
      [this.selected_transaction, this.value].forEach((item) => {
        const suspenseID =
          item.transaction_type === "Cash Out"
            ? "44UTKB7Og5aZLcOvaSOl"
            : "RSAgQsASdXZKhQRwMZUz";
        const suspenseRef = db
          .collection("accounting_totals")
          .doc(item.transaction_date.slice(0, 7))
          .collection("ledger_accounts")
          .doc(suspenseID);

        batch.update(suspenseRef, {
          monthly_total: firebase.firestore.FieldValue.increment(
            -Math.abs(item.transaction_amount).toFixed(2)
          ),
        });
      });

      batch
        .commit()
        .then(() => {
          this.loading = false;
          showSnackbar("Inter account transfers allocated successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>